
* a { color: #e31e25;}
a:hover {
  color: #e31e25;
}

h5, .h5 { color: #e31e25; text-transform: none;}

.icRight { position: relative; margin-top: 15px;  }

.page-main-header .main-header-right svg line {color: #2f3c4e;}

.page-main-header .main-header-right .nav-right ul li svg { color: #e31e25;margin-top: 0px;}

.page-main-header .main-header-right .nav-right ul li .dot { background-color: #e31e25;}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .animate-circle { border: 5px solid #e31e25;}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .main-circle {background-color: #e31e25;}

.card .card-header h5{text-transform: none; font-weight: 400;}

.form-group{ margin-bottom: 25px;}

.form-control{ border-color: #dee2e6 !important;}

.pagination-primary .page-item .page-link {color: #2f3c4e;}
.pagination-primary .page-item.active .page-link {color: #fff; background-color: #e31e25; border-color: #e31e25;}

.checkbox_animated:before{border-color: #e31e25;}
.radio_animated:before { background: #e31e25;}

.radio_animated:after { border: 2px solid #ced4da;}


.authentication-box .btn-primary { background-color: #2f3c4e !important; border-color: #2f3c4e  !important;}

.swal2-styled.swal2-confirm { background-color: #e31e25 !important;}
.swal2-styled.swal2-cancel { background-color: #2f3c4e !important; }

.page-main-header .main-header-right .nav-right .profile-dropdown {  width: 168px;  top: 50px;}
.page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(2) {
    border-top: 1px solid #eff0f1; padding-top: 20px;
}
.page-main-header .main-header-right .nav-right .profile-dropdown h6 {
    color: #313131; text-transform: uppercase; font-weight: 600; letter-spacing: 1.5px; margin-bottom: 3px;
}
.page-main-header .main-header-right .nav-right .profile-dropdown p {
    text-transform: uppercase; font-weight: 600; font-size: 10px; margin-bottom: 0; color: #e31e25; padding-bottom: 15px;
}

#stepRws .footer-buttons{ text-align: center; margin-top: 15px;}

.txtPrivousInteractionStep1{
  overflow-y: scroll;
  max-height: 150vh !important; 
  }
  .txtPrivousInteractionStep2{
    overflow-y: scroll;
    max-height: 90vh !important; 
    }

    .grdH1{ color: #666666; font-size: 16px; font-weight:normal;}
    .grdH2{ color: #666666; font-size: 14px; font-weight:normal; text-decoration: underline; text-decoration-color:#666666;}
    .grdHwth2{ color: #666666; font-size: 14px; font-weight:normal;}
    .grdH3{ color: #666666; font-size: 12px; font-weight:normal; }
    .inner-addon { position: relative;}
.inner-addon .icon { position: absolute; padding: 14px 10px 10px 10px; pointer-events: none;}

.left-addon .icon  { left:  0px;}
.right-addon .icon { right: 0px;}

.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 30px; }
.dzu-dropzone {
    border: 2px dashed #2f3c4e !important;
    background: rgba(0, 0, 0, 0.1);
}

.dzu-inputLabel {
    color: #2f3c4e !important;
}


.pb0{ padding-bottom: 0px;}

.mb15{ margin-bottom: 15px;}
.mb0{margin-bottom: 0px;}

.mt15{ margin-top: 15px;}
.mt20{ margin-top: 20px;}
.mt25{ margin-top: 25px;}
.mt50{ margin-top: 50px;}
.mt175{ margin-top: 175px;}


.text-orng{ color:#fa9029 !important;}
.text-red{ color:#ff0000 !important;}
.text-required{color:#ff0000; font-style: italic; margin-top: 5px; font-size: 14px;}



.page-link{color: #2f3c4e;}
.page-item.active .page-link { background-color: #e31e25; border-color: #e31e25;}
.react-bootstrap-table-pagination{ margin-top: 20px;}

.dropdown-item a{ color: #2f3c4e !important;}



.linkCurson{ cursor: pointer;}
.textUnderline{ text-decoration: underline;}


.pointsInline .list-inline-item:not(:last-child) {
    border-right: 1px solid #fff; padding-right: 8px; margin-bottom: 6px;  margin-top: 6px;
}

/***************21-09-2023 React table*******************/
.txtteamManagement .react-bootstrap-table {
  overflow-x: auto;
}

.txtteamManagement .react-bootstrap-table .table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.txtteamManagement .react-bootstrap-table .table td {
  width: inherit;
}

.page-wrapper .page-body-wrapper .page-header .row h3 {text-transform: none;}

/***************22-04-2022*******************/
.pageDivDisble{
 
 z-index:2;opacity:0.4;filter: alpha(opacity = 50);
 pointer-events: none;
}
.pageUnderConstruction{
  text-align:center;
  font-size:28px !important;
 color:#e31e25 !important;
}
.txtwdthcls{
  width: 600px !important;
}

/************21-02-2022***********/
  .stepper-wrapper * {
    box-sizing: border-box;
  }
  .stepper-wrapper .stepper-head {
    display: flex;
    position: relative;
    width: 100%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* styles of inline stepper */
    /* styles of vertical stepper */
  }
  .stepper-wrapper .stepper-head.inline-stepper-head .stepper-step:after {
    top: 50%;
    transform: translateY(-50%);
  }
  .stepper-wrapper .stepper-head.inline-stepper-head .stepper-indicator,
  .stepper-wrapper .stepper-head.inline-stepper-head .stepper-label {
    display: inline-block;
    vertical-align: middle;
  }
  .stepper-wrapper .stepper-head.inline-stepper-head .stepper-label {
    text-align: left;
    padding: 0 0.5rem;
    background: #fff;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head {
    flex-direction: column;
    width: auto;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step {
    display: flex;
    align-items: center;
    text-align: start;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step:after {
    content: " ";
    position: absolute;
    left: 1.5rem;
    right: auto;
    top: 2rem;
    bottom: -0.5rem;
    width: 0;
    height: auto;
    border-left: 0.125rem solid #e3e8ec;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step.rightToLeft:after {
    right: 1.5rem;
    left: auto;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head .stepper-label {
    -webkit-padding-start: 1rem;
            padding-inline-start: 1rem;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head + .stepper-body {
    -webkit-margin-start: 3.5rem;
            margin-inline-start: 3.5rem;
  }
  .stepper-wrapper .stepper-head .stepper-step {
    position: relative;
    text-align: center;
    padding: 0.5rem;
    flex-basis: 100%;
  }
  .stepper-wrapper .stepper-head .stepper-step.rightToLeft:after {
    left: -50%;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-complete .stepper-indicator-info {
    border-color: #4caf50;
    background-color: #4caf50;
    cursor: pointer;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-complete .stepper-label {
    color: #4caf50;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-active .stepper-indicator-info {
    border-color: #627c90;
    background-color: #627c90;
    cursor: initial;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-active .stepper-label {
    color: #627c90;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-warning .stepper-indicator-info {
    border-color: #f1c40f;
    background-color: #f1c40f;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-warning .stepper-label {
    color: #f1c40f;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-error .stepper-indicator-info {
    border-color: #e95a4b;
    background-color: #e95a4b;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-error .stepper-label {
    color: #e95a4b;
  }
  .stepper-wrapper .stepper-head .stepper-step:after {
    content: " ";
    position: absolute;
    left: 50%;
    top: 1.5rem;
    width: 100%;
    height: 0.125rem;
    background-color: #e3e8ec;
    z-index: 1;
  }
  .stepper-wrapper .stepper-head .stepper-step:last-child:after {
    display: none;
  }
  .stepper-wrapper .stepper-head .stepper-indicator {
    position: relative;
    display: block;
    z-index: 2;
  }
  .stepper-wrapper .stepper-head .stepper-indicator-info {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border: 1px solid #e3e8ec;
    border-radius: 50%;
    background-color: #e3e8ec;
    font-size: 1rem;
    line-height: 2.5rem;
    text-align: center;
    color: #fff;
    z-index: 2;
  }
  .stepper-wrapper .stepper-head .stepper-indicator-info .stepper-tick {
    width: 14px;
    fill: #ffffff;
  }
  .stepper-wrapper .stepper-head .stepper-label {
    position: relative;
    display: block;
    margin: 0.5rem 0;
    color: #cfd7de;
    z-index: 2;
  }
  .stepper-wrapper .stepper-body {
    flex: 1;
    min-height: 100px;
    padding: 10px 0;
  }
  .stepper-wrapper .stepper-footer {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  .stepper-wrapper .stepper-footer-btn {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    cursor: pointer;
    border: none;
    outline: none;
  }
  .stepper-wrapper .stepper-footer-btn.primary {
    color: #fff;
    background-color: #1976d2;
  }
  .stepper-wrapper .stepper-footer-btn.success {
    color: #fff;
    background-color: #4caf50;
  }
  .stepper-wrapper .stepper-footer-btn:disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
    cursor: not-allowed;
  }




  .page-main-header .main-header-right .nav-right .profile-dropdown li:hover a { color: #000 !important;}
  .page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg{ color: #000 !important;}
  .page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg path, .page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg line {
    color: #000!important;
  }

  a.text-dark:hover, a.text-dark:focus { color: #000 !important;}

  .pagination-primary .page-item.active .page-link {background-color: #e31e25;  border-color: #e31e25;}
  h5, .h5 { color: #e31e25;}


.btn-primary {
    background-color: #e31e25 !important;
    border-color: #e31e25 !important;
}

  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
    background-color: #cb1118 !important;
    border-color: #cb1118 !important;
}
  

.signinLogoHeader{ background-color: #e31e25; padding: 10px 0 0 0; text-align: center;}
.logoTagLine{ background-color: #fff; text-align: center; font-size: 10px; color: #000; padding: 1px;}
.logoTagLine span{ color: #e31e25; font-weight: bold;}
.main-header-left{background-color: #e31e25 !important;}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-border { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}



/************** DatePicker css**************/

.dateIcon{position: relative;}
.dateIcon .fa-calendar, .dateIcon .fa-clock-o{position: absolute; right: 10px; top: 50%;  margin-top: -6px;}
.react-datepicker_time-container .react-datepickertime .react-datepicker_time-box {width: 90px !important;}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 90px !important;
}





/************** kavish css**************/
.adPatientColRight{ border-left: solid 1px #dee2e6 ; padding-left: 20px !important; padding-top: 15px; padding-bottom: 15px;}
.adPatientColLeft{ padding-right: 20px !important;}

.calandericon{position: absolute; top: 50%; right: 14px; margin-top: -8px;}
.resendOtp{ padding-left: 10px; padding-right: 10px;}

.swal2-title{ font-size: 30px !important;}
.swal2-content{ font-size: 16px !important; line-height: 1.5 !important;}

.headingstyle3 {font-style: normal;font-weight: 700;font-size: 18px;line-height: 22px;color: #000000;}

.textfullwidth {display: inline-block; width: 100%;}
.timeCol{ padding-top: 10px; padding-bottom: 10px; border-right: solid 1px #fff;}
.idletime{ background: #969798; height: 20px; padding: 0px;}
.activetime{ background: #e10202; height: 20px; padding:0px;}


 /* #phleboSchedule .modal-xl {width: 1400px !important; max-width: 1400px  !important;} */

/******* Mobile******/
@media only screen and (min-device-width:320px) and (max-device-width:767px){
    .page-main-header .main-header-right svg line { color: #fff;}
    .react-bootstrap-table table {
      overflow-x:auto !important;
      display: block;
      table-layout: auto !important;
      }

      .adPatientColRight{ border-left: none !important; border-top: none; padding-left: 0px !important; padding-top: 15px; padding-bottom: 15px; margin-top: 15px;}
      .adPatientColLeft{ padding-right: 0px !important;}

      .cartAccordian .card .card-header{ padding: 0px !important;}

      
 /* #phleboSchedule .modal-xl {width: 1400px !important; max-width: 1400px  !important;} */
}
    
    
    @media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:portrait){  }
    
    
    @media only screen and (min-device-width:320px) and (max-device-width:767px) and (orientation:landscape){ }
    
     
    
    
    /****** IPADs *********/
    @media only screen and (min-device-width:768px) and (max-device-width:1024px){ 
     
    }
    
    
    
    @media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait){
        .page-main-header .main-header-right svg line { color: #fff;}
        .page-main-header .main-header-right .nav-right > ul > li:first-child { width: 80%; }

        .react-bootstrap-table table {
          overflow-x:auto !important;
          display: block;
          table-layout: auto !important;
          }

          .cartAccordian .card .card-header{ padding: 0px !important;}

    
    }
    
    
    @media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape){
      .logoTagLine { padding: 1px; }
    }
    
    
    
    
    /* (1920x1080) Full HD Display */ /*@media  screen and (max-width: 1920px)*/
    @media  screen and (min-width: 1920px) {
    
    }


    @media only screen and (max-width: 991px){
        .page-main-header .main-header-right {background-color: #e31e25; }
    }
    


    @media only screen and (max-width: 991px){
        .page-wrapper .page-main-header .main-header-left .logo-wrapper img { height: 45px; display: block; margin:0 auto; }
        .main-header-left{width: 255px;}
        .page-main-header .main-header-right { padding-left: 0px;}
        /*.logoTagLine{padding: 1px; font-size: 8px;}*/

      

    }
    
    
    
    
    /* iPhone X in portrait & landscape */
    @media only screen 
    and (min-device-width : 375px) 
    and (max-device-width : 812px)
    and (-webkit-device-pixel-ratio : 3) {
         /* STYLES GO HERE */
    }
    
    
    /* iPhone X in portrait */
    @media only screen 
    and (min-device-width : 375px) 
    and (max-device-width : 812px) 
    and (-webkit-device-pixel-ratio : 3)
    and (orientation : portrait) { 
    
    }
    
    
    /* iPhone X in landscape */
    @media only screen 
    and (min-device-width : 375px) 
    and (max-device-width : 812px) 
    and (-webkit-device-pixel-ratio : 3)
    and (orientation : landscape) { 
    
    }
    
    
    
    
    
    /*iPhone 6, 7, & 8 portrait & landscape*/
    @media only screen 
    and (min-device-width : 375px) 
    and (max-device-width : 667px) { /* STYLES GO HERE */}
    
    
    /*iPhone 6, 7, & 8 landscape*/
    @media only screen 
    and (min-device-width : 375px) 
    and (max-device-width : 667px) 
    and (orientation : landscape) { /* STYLES GO HERE */}
    
    
    /*iPhone 6, 7, & 8 portrait & landscape*/
    @media only screen 
    and (min-device-width : 375px) 
    and (max-device-width : 667px) 
    and (orientation : portrait) { /* STYLES GO HERE */ }
    
    
    
    /*iPhone 6, 7, & 8 Plus in portrait & landscape*/
    @media only screen 
    and (min-device-width : 414px) 
    and (max-device-width : 736px) { /* STYLES GO HERE */}
    
    
    /*iPhone 6, 7, & 8 Plus in  landscape*/
    @media only screen 
    and (min-device-width : 414px) 
    and (max-device-width : 736px) 
    and (orientation : landscape) { /* STYLES GO HERE */}
    
    
    /*iPhone 6, 7, & 8 Plus in portrait*/
    @media only screen 
    and (min-device-width : 414px) 
    and (max-device-width : 736px) 
    and (orientation : portrait) { /* STYLES GO HERE */ }
    
    
    
    /*iPhone 5 & 5S in portrait & landscape*/
    @media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 568px) { /* STYLES GO HERE */}
    
    
    /*iPhone 5 & 5S in landscape*/
    @media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 568px) 
    and (orientation : landscape) { /* STYLES GO HERE */}
    
    
    /*iPhone 5 & 5S in portrait*/
    @media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 568px) 
    and (orientation : portrait) { /* STYLES GO HERE */ }
    
    
    
    
    @media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio:2){}
    @media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio:2){}
    @media only screen and (min-width:1224px){}
    @media only screen and (min-width:1824px){}
    @media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio:2){}
    @media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio:2){}
    @media only screen and (min-device-width:320px) and (max-device-height:568px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2){}
    @media only screen and (min-device-width:320px) and (max-device-height:568px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2){}
    @media only screen and (min-device-width:375px) and (max-device-height:667px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2){}
    @media only screen and (min-device-width:375px) and (max-device-height:667px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2){}
    @media only screen and (min-device-width:414px) and (max-device-height:736px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2){}
    @media only screen and (min-device-width:414px) and (max-device-height:736px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2){}
    @media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2){}
    @media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2){}
    @media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:3){}
    @media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:3){}
    @media only screen and (min-device-width:360px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:3){}
    @media only screen and (min-device-width:360px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:3){}
    @media screen and (-webkit-min-device-pixel-ratio:0){}




/***Shiv Sir on 09 Feb 2023***/
.cartAccordian{ position: relative; padding: 20px 0px !important;}
.cartAccordian .card{ border: none; box-shadow: none; margin-bottom: 1px;}
.cartAccordian .card .card-header{ padding: 0px; border: none; /*background: #e9ecef;*/ background: #f4f4f4;}
.cartAccordian .btn-link{color: #212529; font-weight: 700; text-decoration: none; text-align: left; padding: 12px 16px; display: block; width: 100%;}
.accordionheaderleft {display: block; width:70%; float: left; font-size: 16px;}
.accordionheaderright { display: block; width: 30%; float: right;justify-content: end;text-align: right;padding-right: 20px;}
.accordionheaderright span {color: #999999;min-width: 30px;display: block;}
.accordionheaderright span {color: #999999;display: block;float: right;text-align:right;margin-right: 10px;}
.cartsec { display: block; width: 100%; height: auto; background: #FFFFFF;;}
.carttitlesec { display: block; width: 100%; height: auto; padding-top: 16px; padding-left: 20px;}
.carttitlesec h2 { font-style: normal; font-weight: 700!important; font-size: 18px!important; line-height: 19px!important; color: #000000!important;}
.carttitlesec p {font-weight: 400; line-height: 19px; color: #999999;}
.cartmainsec span { color: #999999;padding-left: 5px;}
.cartsec .cartfootersec { width: 100%; padding: 27px; display: flex; justify-content: right;}
.cartsec .cartfootersec button { border: none; background-color: #000000; color: #ffffff; padding: 7px 20px;}
.cartmainsec ul { list-style: none; padding: 0;}
.cartmainsec ul li { font-size: 15px; color: black; font-weight: 500;width: 100%;float: left;margin-bottom: 15px;}
.cartmainsec ul li div { display: block; float: left; width: 12px; height: 50px;}
.cartmainsec ul li p { color: #999999; font-size: 15px; font-weight: 400;}
.cartmainsec ul li div i { display: block; border: 1px solid #E31E25; width: 17px; height: 16px; text-align: center; padding-top: 1px; color: #E31E25; font-size: 12px; margin-top: 5px;}
.cartmainsec span { color: #999999; padding-left: 5px;}
.cartmainsec .salePrice { font-size: 15px; color: #e31e25; font-weight: 500;}
.cartmainsec .comparePrice { font-size: 15px; color: #999; font-weight: 500; text-decoration: line-through;}
.cartmainsec .offPrice { font-size: 15px; color: #339933; font-weight: 500;}
.cartTextBlack { font-weight: 500; font-size: 16px; line-height: 20px; color: #0c0c0c;}
.findCenter .cartTextBlackNext { font-weight: 500; font-size: 16px; line-height: 20px; color: #0c0c0c; margin-bottom: 20px;}
.cartmainsec .card .card-header {border-top-left-radius: 0px; border-top-right-radius: 0px;}
/***Shiv Sir on 09 Feb 2023***/

.accordionheaderleft span{ font-size: 14px; font-weight: 500;}

/* .accordion-button::after { flex-shrink: 0; width: 1.25rem; height: 1.25rem; margin-left: auto; content: ""; background-image: url("/images/accodianArrowIconBlack.svg"); background-repeat: no-repeat; background-size: 1.25rem; transition: transform 0.2s ease-in-out;transform: rotate(-93deg);} */

/* .cartAccordian .btn-link:after{ content: ""; position: absolute; right: 12px;  width: 1.25rem; height: 1.25rem; background-image: url("/images/accodianArrowIconBlack.svg"); background-repeat: no-repeat; background-size: 1.25rem; transition: transform 0.2s ease-in-out;}
.cartAccordian .btn-link.active:after{    transform: rotate(-180deg);} */

.fw-bold {font-weight: 700 !important; }

.btn-light { color: #000; background-color: #f8f9fa;border-color: #f8f9fa;}


.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.calandericon1{position: absolute; top: 50%; right: 26px !important; margin-top: -4px !important;}

.text-patientName{ font-size: 16px;}
.ageGender{ font-size: 14px;}

.ft18{ font-size: 18px;}

.secWrapper .text-required{ position: absolute;}

.card .card-header{  padding: 15px 30px;}




.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform,transition;
}


/* Carausel slider start */
.react-multiple-carousel__arrow::before { font-size: 25px; color: #000; display: block; font-family: revicons; text-align: center;z-index: 2; position: relative;}
.react-multiple-carousel__arrow { position: absolute; outline: 0; transition: all .5s; border-radius: 35px; z-index: 1000; border: 0; background: #e31e25; min-width: 43px;min-height: 43px; opacity: 1;cursor: pointer;}
.react-multiple-carousel__arrow:hover {background: #000;}
/* Carausel slider End */

.react-multi-carousel-list .react-multiple-carousel__arrow--left {
  left: calc(-1% + 15px);
}
.react-multi-carousel-list .react-multiple-carousel__arrow--right {
  right: calc(-1% + 15px); content: '>'
}

.react-multi-carousel-list .react-multiple-carousel__arrow--left::after {
   color: #fff; font-family: FontAwesome; content: '\f060'; 
}

.react-multi-carousel-list .react-multiple-carousel__arrow--right::after {
  color: #fff; font-family: FontAwesome; content: '\f061';
}


.react-datepicker .react-datepicker__day--today{ background-color: #fff !important; color: #000 !important;}
.react-datepicker .react-datepicker__day--selected {    background-color: #e31e25 !important; color: #fff !important;}

#cover-spin {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index:9999;
  /* display:none;  */
}

@-webkit-keyframes spin {
from {-webkit-transform:rotate(0deg);}
to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
from {transform:rotate(0deg);}
to {transform:rotate(360deg);}
}

#cover-spin::after {
  content:'';
  display:block;
  position:absolute;
  left:48%;top:40%;
  width:40px;height:40px;
  border-style:solid;
  border-color:#192790;
  border-top-color:transparent;
  border-width: 4px;
  border-radius:50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

.blink {
  animation: blinker .75s linear infinite; 
  color: red;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding: 8px 16px;
}
@keyframes blinker {
  50% {
      opacity: 0;
  }
}
.blink .fa{font-size: 26px;}

