.bgTransparent{background: none !important; padding-top: 20px; padding-bottom: 10px; font-size: 18px; color: #000;}
.salePrice{ font-size: 16px; font-weight: bold; color: #e31e25;}
.comparePrice{font-size: 16px; color: #999; text-decoration: line-through;}
.offPrice{font-size: 16px; color: #339933; font-weight: bold;}
.textDark{color: #000; }
.textDiscount{color:#339933;}
.textTotal{ font-size: 18px; font-weight: bold; color: #000; text-align: left;}
.priceTotal{ font-size: 18px; font-weight: bold; color: #e31e25;}


.mt20{margin-top: 20px;}
.mb20{margin-bottom: 20px;}


.rdCollection{font-size: 16px; font-weight: 600; color: #000 !important;}

.collectionCard, .collectionCardHome{ border: solid 1px #d3d3d3; padding: 30px; position: relative; margin-bottom: 15px; margin-left: 22px; margin-right: 22px; min-height: 190px;}
.collectionCardHome{min-height: 150px;}
.collectionCard h2, .collectionCardHome h2{ font-size: 16px; font-weight: 600; color: #000; margin-bottom: 16px;}
.txtMob, .txtDate, .txtBlk{font-size: 16px; font-weight: 600; color: #000;}
.actionBtn{font-size: 16px; font-weight: 600; color: #000; text-decoration: none;}
/* .actionBtn:hover{color: #e31e25;} */


.collectionCard .selectFlag, .collectionCardHome .selectFlag{background: url(/images/bg-colllectionActive.png) no-repeat center; position: absolute; top: 0; right: 0; width: 43px; height: 43px; display: none; }

.collectionCard:hover , .collectionCardHome:hover{box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);}
.collectionCard.active , .collectionCardHome.active{border: solid 1px #e31e25; }

.collectionCard:hover .selectFlag, .collectionCard.active .selectFlag , 
.collectionCardHome:hover .selectFlag, .collectionCardHome.active .selectFlag{display: block; }


/***Shiv Sir***/

.orderpara{ font-size: 14px !important;margin-bottom: 8px !important;}





/******* Mobile******/
    @media only screen and (min-device-width:320px) and (max-device-width:767px){

    }
    
    
    @media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:portrait){  }
    
    
    @media only screen and (min-device-width:320px) and (max-device-width:767px) and (orientation:landscape){ }
    
     
    
    
    /****** IPADs *********/
    @media only screen and (min-device-width:768px) and (max-device-width:1024px){ 
     
    }
    
    
    
    @media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait){
        .collectionCardHome{min-height: 175px;}
        
    }
    
    
    @media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape){
      
    }


